var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("c-table", {
        attrs: {
          isTitle: "",
          title: "안전조치 요구사항",
          columns: _vm.grid.columns,
          gridHeightAuto: true,
          data: _vm.supWork.checkResults,
          filtering: false,
          columnSetting: false,
          usePaging: false,
          editable: _vm.editable && _vm.isWriting,
          contentsField: _vm.contentsField,
        },
      }),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }